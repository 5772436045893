<template>
  <div :class="['marker', { active, fail, pass }]">
    <span v-if="multi_fail" class="fails">2</span>
    <span>{{ mission.players }}</span>
  </div>
</template>

<script>
export default {
  name: "MissionMarker",
  props: ["mission", "active", "fail", "pass", "multi_fail"]
};
</script>

<style lang="stylus" scoped>
.marker
  position relative
  display flex
  align-items center
  justify-content center
  width 10vw
  height 10vw
  border-radius 50%
  background-color: colors.blue
  transition background-color 300ms

  @media (min-width: breaks.md)
    width 100px
    height 100px


  &.fail
    background-color: colors.error
  &.pass
    background-color: colors.success
  &.active
    background-color: colors.light
    color: colors.dark

  span
    font-size 20px
    @media (min-width: breaks.sm)
      font-size 36px

span.fails
  position absolute
  top -5px
  right -5px
  width 18px
  height 18px
  display flex
  align-items center
  justify-content center
  border-radius 50%
  background-color: colors.spy
  color white
  font-size 14px
  @media (min-width: breaks.md)
    top 0
    right 0
    font-size 20px
    width 30px
    height 30px
</style>
