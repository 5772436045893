<template>
  <div class="missions">
    <div class="flex">
      <MissionMarker
        :key="index"
        v-for="(mission, index) of missions"
        :mission="mission"
        :active="round === index + 1"
        :pass="mission.pass_fail && round > index + 1"
        :fail="!mission.pass_fail && round > index + 1"
        :multi_fail="mission.votes_fail > 1"
      />
    </div>
  </div>
</template>

<script>
import MissionMarker from "@/components/MissionMarker";

export default {
  name: "Missions",
  components: { MissionMarker },
  props: ["missions", "round"]
}
</script>

<style lang="stylus" scoped>
.missions
  margin-bottom 48px
.flex
  display flex
  justify-content space-between
</style>