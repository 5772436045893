<template>
  <div
    class="player"
    :class="{
      'on-mission': player.on_mission && (leader || team_confirmed),
      'resistance': reveal && !player.is_spy,
      'spy': reveal && player.is_spy
    }"
  >
    <h4>{{ player.name }}</h4>
    <div class="actions">
      <button v-if="canSend" @click="activatePlayer">
        {{ player.on_mission ? "Nah" : "send 'em" }}
      </button>
    </div>
    <font-awesome-icon
      :class="['crown',{active: game_started && player.leader }]"
      icon="crown"
    />
    <div class="icons">
      <template v-if="reveal">
        <font-awesome-icon v-if="player.is_spy" icon="user-secret" />
        <font-awesome-icon v-else icon="fist-raised" />
      </template>
      <font-awesome-icon v-else icon="user-tie" />
    </div>
    <div :class="['voted', {'active': voted}]">
      <p>VOTED</p>
    </div>
  </div>
</template>

<script>
import { db } from "@/store/firebase";

export default {
  name: "Player",
  props: [
    "player",
    "game_id",
    "game_started",
    "my_player",
    "leader",
    "reveal",
    "voted",
    "all_selected",
    "team_confirmed"
  ],
  methods: {
    activatePlayer() {
      db.ref(`/games/${this.game_id}/players/${this.player.name}/on_mission/`).set(!this.player.on_mission);
    }
  },
  computed: {
    canSend() {
      return this.game_started &&
        this.leader &&
        (this.player.on_mission || !this.all_selected) &&
        !this.player.voted_team &&
        !this.team_confirmed;
    }
  }
};
</script>

<style lang="stylus" scoped>
.player
  position relative
  text-align center
  padding 12px 12px 40px
  border-radius 6px
  background-color: colors.dark + 80%
  color: colors.dark
  transition background-color 300ms
  overflow hidden
  min-height 200px

  &.resistance
    .icons
    h4
      color: colors.blue

  &.spy
    .icons
    h4
      color: colors.spy

  &.on-mission
    background-color: colors.blue

  &.resistance.on-mission
    h4
    .icons
      color white

h4
  @media (max-width: breaks.md)
    font-size 18px

.actions
  position absolute
  bottom 6px
  left 6px
  width calc(100% - 12px)

.crown
  color: colors.gold
  font-size 30px
  visibility hidden
  margin-bottom 6px

  &.active
    visibility visible

.set-player
  margin-top 12px

p
  margin 0

.icons
  font-size 55px
  margin-bottom 12px
  line-height 1.25

.voted
  position absolute
  bottom -20%
  left 0
  width 101%
  display flex
  justify-content center
  align-items center
  font-weight bold
  letter-spacing 2px
  padding 6px 0
  background-color black
  transition bottom 300ms
  color white

  &.active
    bottom -1px
</style>
