<template>
  <div class="vote-tracker">
    <h4>Vote Track</h4>
    <div class="votes">
      <VoteMarker
        :key="index"
        v-for="(vote, index) of 5"
        :failed="vote <= votes.votes_failed"
        :passed="vote == votes.votes_passed + votes.votes_failed"
        :vote="vote"
      />
    </div>
  </div>
</template>

<script>
import VoteMarker from "@/components/VoteMarker";

export default {
  name: "VoteTracker",
  components: { VoteMarker },
  props: ["votes"]
};
</script>

<style lang="stylus" scoped>
h4
  margin-bottom 24px

.votes
  display flex
  align-items center
  justify-content space-between

  @media (min-width: breaks.md)
    width 50%
    justify-content flex-start
</style>
