<template>
  <div class="game">
    <div id="nav">
      <router-link to="/">Home</router-link>
    </div>
    <div v-if="error">
      <p>Sorry, something went wrong.</p>
    </div>
    <template v-if="game && !error">
      <p class="code">Room: <b>{{ game.id.toUpperCase() }}</b></p>
      <Board :game="game" />
      <template v-if="player">
        <Players
          :game="game"
          :my_player="player"
          :all_selected="allSelected"
        />
        <template v-if="game.started">
          <div class="button-actions">
            <button :disabled="!showConfirmTeam" @click="confirmTeam">
              confirm team
            </button>
            <button
              :disabled="!showVoteTeam"
              @click="teamVoteWindow = true"
            >
              vote team
            </button>
            <button
              :disabled="!showVoteMission"
              @click="missionVoteWindow = true">
              vote mission
            </button>
          </div>
          <Vote
            v-if="teamVoteWindow"
            :title="'Team Vote'"
            :game="game"
            :type="'team'"
            :player="player"
            @close="closeWindows"
          />
          <Vote
            v-if="missionVoteWindow"
            :title="'Mission Vote'"
            :game="game"
            :type="'mission'"
            :player="player"
            @close="closeWindows"
          />
          <Reveal
            v-if="game.reveal"
            :game="game"
            :player="player"
            @start-mission="startMission"
            @reset-vote="resetTeamVote"
            @next-mission="nextMission"
            @new-game="reset"
          />
        </template>
        <div v-else-if="Object.keys(game.players).length >= 5" class="button-actions">
          <button class="success" @click="startGame">start game</button>
        </div>
      </template>
      <AddPlayer
        v-else-if="!game.started"
        :game_id="game.id"
        :players="game.players"
        @close="closeWindows"
        @add-player="reset"
      />
      <Setup
        :code="game.id"
        :players="game.players"
        @reset="reset"
      />
    </template>
  </div>
</template>

<script>
import Vote from "@/components/Vote";
import Setup from "@/components/Setup";
import Board from "@/components/Board";
import Players from "@/components/Players";
import AddPlayer from "@/components/AddPlayer";
import Reveal from "@/components/Reveal";
import { db } from "@/store/firebase";
import { PLAYER, TEAMS, GAME, VOTES, MISSIONS, getRandomKeys } from "../store/constants";

export default {
  name: "Game",
  components: {
    Setup,
    Vote,
    Board,
    Players,
    AddPlayer,
    Reveal
  },
  data() {
    return {
      game: null,
      error: false,
      teamVoteWindow: false,
      missionVoteWindow: false,
      addPlayerWindow: false,
      revealSpies: false,
      player: null
    };
  },
  mounted() {
    db.ref(`/games/${this.$route.params.id.toLowerCase()}`).on("value", snapshot => {
      this.error = !snapshot.exists();

      if (snapshot.exists()) {
        const data = snapshot.val();
        const name = localStorage.getItem('spygame_name');
        this.game = data;
        this.player = name in data.players ? data.players[name] : null;
        // console.log('[GAME_UPDATE]', data)
      }
    });
  },
  methods: {
    moveLeader() {
      const { players } = this.game;
      const playerNames = Object.keys(players);
      const currentLeader = playerNames.find(name => players[name].leader);
      const newLeader = playerNames.find(name => {
        return players[currentLeader].position < playerNames.length - 1 ?
          players[name].position == players[currentLeader].position + 1 :
          players[name].position == 0;
      });
      return [currentLeader, newLeader];
    },
    startGame() {
      this.reset();
      db.ref(`/games/${this.game.id}/started/`).set(true, error => this.error = error);
    },
    startMission() {
      const updates = {
        [`/games/${this.game.id}/reveal/`]: false,
        [`/games/${this.game.id}/reveal_score/`]: false,
      };

      db.ref().update(updates, error => {
        this.error = error;
      });
    },
    resetTeamVote() {

      const { players } = this.game;

      const playerNames = Object.keys(players)

      const [currentLeader, newLeader] = this.moveLeader();
      
      const updates = {
        [`/games/${this.game.id}/reveal/`]: false,
        [`/games/${this.game.id}/reveal_score/`]: false,
        [`/games/${this.game.id}/team_confirmed/`]: false,
        [`/games/${this.game.id}/team_votes/pass`]: 0,
        [`/games/${this.game.id}/team_votes/fail`]: 0,
        [`/games/${this.game.id}/players/`]: playerNames.reduce((acc, player) => {
          return {
            ...acc,
            [player]: {
              ...players[player],
              on_mission: false,
              last_vote: null,
              voted_mission: false,
              voted_team: false,
              leader: player == newLeader
            }
          };
        }, {})
      };

      db.ref().update(updates, error => {
        this.error = error;
      });
    },
    nextMission() {
      const {
        players,
        mission_votes,
        missions,
        round,
        score
      } = this.game;

      if (this.game.round < this.game.missions.length) {
        const playerNames = Object.keys(players);
        const [currentLeader, newLeader] = this.moveLeader();

        db.ref(`/games/${this.game.id}/`).set({
          ...this.game,
          reveal: false,
          reveal_score: false,
          team_confirmed: false,
          mission_votes: { ...VOTES },
          team_votes: { ...VOTES },
          players: playerNames.reduce((acc, player) => {
            return {
              ...acc,
              [player]: {
                ...players[player],
                on_mission: false,
                last_vote: null,
                voted_mission: false,
                voted_team: false,
                leader: player == newLeader
              }
            };
          }, {}),
          round: round + 1,
          missions: {
            ...missions,
            [round - 1]: {
              ...missions[round - 1],
              pass_fail: mission_votes.fail < missions[round - 1].votes_fail
            }
          }
        }, error => this.error = error);
        
        this.closeWindows();
      }
    },
    toggleWindow(window) {
      this.closeWindows();
      const newVal = !this.$data[window];
      this.$data[window] = newVal;
    },
    closeWindows() {
      this.reveal = false;
      this.addPlayerWindow = false;
      this.teamVoteWindow = false;
      this.missionVoteWindow = false;
    },
    reset() {
      const { id, players } = this.game;
      const playerKeys = Object.keys(players);
      const leaderIndex = getRandomKeys(playerKeys, 1);
      const spyIndex = getRandomKeys(
        playerKeys,
        TEAMS[Object.keys(players).length].spies
      );

      const mapped_players = playerKeys.reduce((acc, name) => {
        return {
          ...acc,
          [name]: {
            ...players[name],
            name,
            selected: false,
            leader: leaderIndex.includes(name),
            on_mission: false,
            is_spy: spyIndex.includes(name),
            last_vote: null,
            voted_team: false,
            voted_mission: false
          }
        };
      }, {});

      db.ref(`/games/${this.game.id}/`).set({
        ...GAME,
        id,
        players: mapped_players,
        team_comp: TEAMS[Object.keys(players).length],
        missions: MISSIONS[Object.keys(players).length]
      }, error => this.error = error);
      this.closeWindows();
    },
    confirmTeam() {
      db.ref(`/games/${this.game.id}/team_confirmed`).set(true, error => {
        this.error = error;
      });
    }
  },
  computed: {
    allSelected() {
      const playerNames = Object.keys(this.game.players);
      const onMission = playerNames.filter(name => this.game.players[name].on_mission);
      return onMission.length == this.game.missions[this.game.round - 1].players;
    },
    showVoteTeam() {
      return this.game.team_votes.votes_passed == 0 &&
        !this.player.voted_team &&
        this.allSelected &&
        this.game.team_confirmed;
    },
    showConfirmTeam() {
      return this.player.leader &&
        !this.game.team_confirmed &&
        this.allSelected;
    },
    showVoteMission() {
      const { team_votes, reveal } = this.game;
      return team_votes.votes_passed > 0 &&
        this.game.team_confirmed &&
        !this.player.voted_mission &&
        this.player.on_mission &&
        !reveal;
    },
    playersInGame() {
      return Object.keys(this.game.players).length;
    }
  }
};
</script>

<style lang="stylus" scoped>
.game
  width calc(100% - 40px)
  max-width 1024px
  margin 0 auto
  padding-bottom 100px

  @media (min-width: breaks.md)
    padding-bottom 0

#nav
  margin: 24px 0

.button-actions
  position fixed
  display flex
  justify-content space-between
  width 100%
  bottom 0
  left 0
  padding 12px
  background-color: black

  @media (min-width: breaks.sm)
    position sticky
    background-color: colors.dark
    padding 12px 0
  
  button 
    width calc(33.33% - 12px)

    &.success
      width 100%
</style>
