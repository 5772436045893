<template>
  <div class="add-player modal">
    <div>
      <font-awesome-icon
        class="close-button"
        @click="$emit('close')"
        icon="window-close"
      />
    </div>
    <p v-if="error" class="error">Pick a different name</p>
    <input
      type="text"
      placeholder="Name"
      v-model.trim="name"
      @keyup.enter="addPlayer"
      autofocus
    />
    <button class="success" @click="addPlayer">Add Player</button>
  </div>
</template>

<script>
import { db } from "@/store/firebase";
import { PLAYER, MISSIONS } from "../store/constants";

export default {
  name: "AddPlayer",
  props: ["game_id", "players"],
  data() {
    return {
      name: "",
      error: false
    };
  },
  methods: {
    async addPlayer() {
      const refURL = `/games/${this.game_id}`;
      const name = this.name.toLowerCase();

      if (name in this.players == false) {
        try {
          localStorage.setItem('spygame_name', name);
          const { snapshot } = await db.ref(`${refURL}`).transaction(game => {
            const num_players = Object.keys(game.players).length
            return {
              ...game,
              players: {
                ...game.players,
                [name]: {
                  ...PLAYER,
                  name,
                  position: num_players
                }
              },
              missions: MISSIONS[num_players > 5 ? num_players + 1 : 5]
            };
          });
        } catch (error) {
          console.error(error);
          this.$emit('error', error);
        }
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.add-player
  @media (min-width: breaks.sm)
    max-width 500px

.error
  color: colors.red
  font-size 18px

input
  display block
  width 100%
  margin-bottom 12px

.close-button
  position absolute;
  top 10px
  left 10px
  margin-bottom 12px
  font-size 22px
  cursor pointer
</style>
