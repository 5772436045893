<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="stylus">
@import "./styles/index.styl";

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color white
</style>
