<template>
  <div class="generate">
    <form v-if="newGame" @submit.prevent="createGame">
      <input
        type="text"
        placeholder="Name"
        maxlength="10"
        required
        v-model.trim="name"
      />
      <div class="flex">
        <button class="success" type="submit">
          Create Room
        </button>
        <button @click.prevent="newGame = false">
          Join Game
        </button>
      </div>
    </form>
    <form v-else @submit.prevent="$router.push(`/${code}`)">
      <input
        type="text"
        placeholder="Code"
        length="5"
        required
        v-model.trim="code"
      />
      <div class="flex">
        <button class="success" type="submit">
          Join Game
        </button>
        <button @click.prevent="newGame = true">
          Create Room
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { db } from "../store/firebase";
import {
  TEAMS,
  PLAYER,
  MISSIONS,
  GAME,
  getRandomKeys
} from "../store/constants";
import { customAlphabet } from "nanoid";
import englishLowercase from "nanoid-dictionary/lowercase";

const nanoid = customAlphabet(englishLowercase, 4);

export default {
  name: "Generate",
  data() {
    return {
      name: "",
      newGame: true,
      code: ""
    };
  },
  methods: {
    createGame() {
      const id = nanoid();
      const name = this.name.toLowerCase();

      console.log(db.ref(`games/${id}`))

      db.ref(`games/${id}`).set(
        {
          ...GAME,
          id,
          players: {[name]: { ...PLAYER, name }},
          team_comp: TEAMS[5],
          missions: MISSIONS[5]
        },
        error => {
          if (error) {
            throw error;
          } else {
            console.log(name)
            localStorage.setItem('spygame_name', name);
            this.$router.push(`/${id}`);
          }
        }
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.generate
  {panel}
  max-width 400px
  padding 24px
  margin 0 auto

  @media (min-width: breaks.md)
    width 50%
    margin 0 auto
    background-color colors.dark + 20%


  h4
    padding 12px
    background-color: colors.blue
    border-radius 4px
  
  label
    font-weight bold
    font-size 16px
    text-align center
    margin-bottom 12px

  input
    text-transform uppercase
    font-weight bold
    letter-spacing 1px

  .flex
    display flex
    justify-content space-between

    button
      width 48%
</style>
