import firebase from "firebase/compat/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/compat/analytics";
import 'firebase/compat/app-check';

// Add the Firebase products that you want to use
// import "firebase/auth";
import "firebase/compat/database";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCHQZM6C422dRMqyxNNGcXYxauHqctyQMY",
  authDomain: "spy-game-7f773.firebaseapp.com",
  databaseURL: "https://spy-game-7f773-default-rtdb.firebaseio.com",
  projectId: "spy-game-7f773",
  storageBucket: "spy-game-7f773.appspot.com",
  messagingSenderId: "256442373185",
  appId: "1:256442373185:web:943e9160e5d0266f6fe2c0",
  measurementId: "G-QB5NPK386G"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.NODE_ENV === 'development';

firebase.analytics();

// App Check
const appCheck = firebase.appCheck();
appCheck.activate('6LfytfccAAAAAMFsQi9u92M7FyGfv6iV2tOcJ6Ny', true);

export const db = firebase.database();
