<template>
  <div :class="['marker', { failed, passed, last: vote == 5 }]">
    <span v-if="vote != 5">{{ vote }}</span>
    <font-awesome-icon class="icon" v-else icon="skull"/>
  </div>
</template>

<script>
export default {
  name: "VoteMarker",
  props: ["vote", "failed", "passed"]
};
</script>

<style lang="stylus" scoped>
.marker
  display flex
  align-items center
  justify-content center
  width 8vw
  height 8vw
  border-radius 50%
  background-color: colors.dark + 20%
  transition background-color 300ms

  @media (min-width: breaks.md)
    width 60px
    height 60px
    margin-right 24px


  &.passed
    background-color: colors.success

  &.failed
    background-color: colors.spy

  .icon
    font-size 18px
    @media (min-width: breaks.md)
      font-size 28px

  span
    font-size 14px
    color: colors.light

    @media (min-width: breaks.md)
      font-size 22px
</style>
