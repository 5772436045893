<template>
  <div class="home">
    <h1>Create Game</h1>
    <Generate />
  </div>
</template>

<script>
import Generate from "@/components/Generate.vue";

export default {
  name: "Home",
  components: {
    Generate
  }
};
</script>

<style lang="stylus" scoped>
.home
  padding-top 100px
h1
  text-align center
</style>
