<template>
  <div class="board">
    <h4 class="title">{{ Object.keys(game.players).length }} Players | {{ game.team_comp.spies }} Spies</h4>
    <Missions :round="game.round" :missions="game.missions" />
    <VoteTracker :votes="game.team_votes" />
  </div>
</template>

<script>
import Missions from "@/components/Missions";
import VoteTracker from "@/components/VoteTracker";

export default {
  name: "Board",
  components: { Missions, VoteTracker },
  props: ["game"]
};
</script>

<style lang="stylus" scoped>
.board
  position relative
  padding 20px
  background-color: colors.dark + 10%
  border-radius 8px
  margin-bottom 24px
  @media (min-width: breaks.md)
    padding 40px

.title
  margin-bottom 24px
  @media (min-width: breaks.md)
    margin-bottom 48px
</style>
