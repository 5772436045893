<template>
  <div class="vote">
    <div class="modal">
      <h4>{{ title }}</h4>
      <div>
        <font-awesome-icon
          class="close-button"
          @click="$emit('close')"
          icon="window-close"
        />
      </div>
      <div class="flex">
        <button
          :class="['success','first-btn', {'no-fail': !canFail}]"
          @click="vote('pass')"
        >
          pass
        </button>
        <button
          v-if="canFail"
          class="fail"
          @click="vote('fail')"
        >
          fail
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/store/firebase";

export default {
  name: "Vote",
  props: [
    "game",
    "type",
    "title",
    "player",
    "move_leader"
  ],
  methods: {
    async vote(val) {
      const refURL = `/games/${this.game.id}`;
      const {
        team_votes,
        mission_votes,
        players,
        missions,
        round
      } = this.game;
      const mission = missions[round - 1];
      const votes = this.type == "team" ? team_votes : mission_votes;

      const updates = {
        [`${refURL}/players/${this.player.name}/voted_${this.type}`]: true,
        [`${refURL}/players/${this.player.name}/last_vote`]: val == 'pass'
      };

      console.log(val)

      try {
        const votesURL = `${refURL}/${this.type}_votes/`;
        const { snapshot } = await db.ref(votesURL).transaction(votes => {
          votes[val]++;
          return votes;
        });

        const updatedVotes = snapshot.val();
        updates[`${refURL}/reveal/`] = this.type == "team" ?
          updatedVotes.pass + updatedVotes.fail == Object.keys(players).length :
          updatedVotes.pass + updatedVotes.fail == mission.players;

      } catch (error) {
        throw error
      }

      db.ref().update(updates, error => this.$emit('error', error));

      this.$emit("close");
    }
  },
  computed: {
    canFail() {
      console.log(this.player)
      return this.type == 'team' || this.player.is_spy;
    }
  }
};
</script>

<style lang="stylus" scoped>

.modal button
  width calc(50% - 12px)
  padding 24px 0
  flex-shrink 0

  &.no-fail
    width 100%

.flex
  display flex
  justify-content space-between

.close-button
  position absolute;
  top 10px
  right 10px
  margin-bottom 12px
  font-size 22px
  cursor pointer
</style>
