<template>
  <div class="setup">
    <div v-if="active" class="panel">
      <div class="buttons">
        <button class="fail" @click="reset">reset</button>
      </div>
    </div>
    <div class="settings-button" @click="active = !active">
      <font-awesome-icon icon="sliders-h" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Setup",
  props: ["players", "code"],
  data() {
    return {
      active: false
    };
  },
  methods: {
    reset() {
      this.$emit('reset');
      this.active = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.setup
  position fixed
  top 0
  bottom auto
  right 0

  @media (min-width: breaks.md)
    right 40px
    top auto
    bottom 100px

.panel
  padding 12px
  border-radius 6px
  background-color: colors.dark + 10%
  margin-bottom 12px

.settings-button
  display flex
  justify-content center
  align-items center
  background-color: white
  cursor pointer
  user-select none
  width 40px
  height 40px
  float right
  border-bottom-left-radius 12px

  @media (min-width: breaks.md)
    border-radius 50%

  svg
    font-size 20px
    color black

.close-button
  position: fixed;
  top: 10px;
  right: 10px;
  cursor pointer
</style>
