<template>
  <div class="players">
    <Player
      :key="player.name"
      v-for="player of sortedPlayers"
      :game_id="game.id"
      :player="player"
      :leader="my_player.leader"
      :all_selected="all_selected"
      :game_started="game.started"
      :voted="(player.voted_team && game.team_votes.votes_passed == 0) || player.voted_mission"
      :reveal="game.started && (my_player.name == player.name || (my_player.is_spy && player.is_spy))"
      :team_confirmed="game.team_confirmed"
      @player-set="$emit('player-set')"
    />
  </div>
</template>

<script>
import Player from "@/components/Player";

export default {
  name: "Players",
  components: { Player },
  props: [ 
    "game",
    "my_player",
    "all_selected"
  ],
  computed: {
    sortedPlayers() {
      const { players } = this.game;
      const keys = Object.keys(players).sort((a, b) => players[a].position - players[b].position);
      return keys.reduce((acc, name) => {
        return {
          ...acc,
          [name]: players[name]
        };
      }, {});
    }
  }
};
</script>

<style lang="stylus" scoped>
.players
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-gap 24px
  margin-bottom 24px
  @media (min-width: breaks.md)
    grid-template-columns repeat(5, 1fr)
</style>
