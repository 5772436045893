export const PLAYER = {
  name: "",
  is_spy: false,
  selected: false,
  on_mission: false,
  leader: false,
  position: 0,
  voted_team: false,
  voted_mission: false,
  last_vote: null
}

export const TEAMS = {
  3: {
    resistance: 2,
    spies: 1
  },
  5: {
    resistance: 3,
    spies: 2
  },
  6: {
    resistance: 4,
    spies: 2
  },
  7: {
    resistance: 4,
    spies: 3
  },
  8: {
    resistance: 5,
    spies: 3
  },
  9: {
    resistance: 6,
    spies: 3
  },
  10: {
    resistance: 6,
    spies: 4
  }
};

const MISSIONS = Object.entries({
  5: [2, 3, 2, 3, 3],
  6: [2, 3, 4, 3, 4],
  7: [2, 3, 3, 4, 4],
  8: [3, 4, 4, 5, 5],
  9: [3, 4, 4, 5, 5],
  10: [3, 4, 4, 5, 5]
}).reduce((acc, [game, rounds]) => {
  return {
    ...acc,
    [game]: rounds.map((players, index) => ({
      players: players,
      votes_fail: game > 6 && index == 3 ? 2 : 1,
      pass_fail: false
    }))
  }
}, {})

export { MISSIONS };

export const VOTES = {
  pass: 0,
  fail: 0,
  votes_passed: 0,
  votes_failed: 0
};

export const GAME = {
  mission_votes: { ...VOTES },
  team_votes: { ...VOTES },
  round: 1,
  score: {
    resistance: 0,
    spies: 0
  },
  players: [],
  game_over: false,
  reveal: false,
  reveal_score: false,
  reveal_spies: false,
  team_confirmed: false,
  started: false
};

export const getRandomKeys = (arr, n) => {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};