import { db } from "@/store/firebase";

const gameGuard = async (to, from, next) => {
  try {
    const { id } = to.params
    const snapshot = await db.ref(`/games/${id.toLowerCase()}`).get()
    snapshot.exists() ? next() : next('/')
  } catch(error) {
    console.error(error)
    next('/')
  }
}

export default {
  gameGuard
}