<template>
  <div class="reveal">
    <div v-if="game.reveal_score" class="window content">
      <div v-if="game.game_over" class="game-over">
        <h1>Game Over</h1>
        <font-awesome-icon
          v-if="spiesWin"
          class="winner-icon spy"
          icon="user-secret"
        />
        <font-awesome-icon
          v-else
          class="winner-icon resistance"
          icon="fist-raised"
        />
      </div>
      <h2>{{ title }}</h2>
      <div class="flex">
        <div v-if="voteType == 'team'" class="team-results">
          <div class="player-col">
            <p
              class="player pass"
              :key="player"
              v-for="player of playersPassed"
            >
              {{ player }}
            </p>
          </div>
          <div class="player-col">
            <p
              class="player fail"
              :key="player"
              v-for="player of playersFailed"
            >
              {{ player }}
            </p>
          </div>
        </div>
        <div v-else class="mission-results">
          <div>
            <p><span class="total-pass">PASS:</span> {{ game.mission_votes.pass }}</p>
          </div>
          <div>
            <p><span class="total-fail">FAIL:</span> {{ game.mission_votes.fail }}</p>
          </div>
        </div>
      </div>
      <template v-if="player.leader">
        <button v-if="game.game_over" @click="$emit('new-game')">New Game</button>
        <template v-else>
          <template v-if="voteType == 'team'">
            <button v-if="votePassed" @click="$emit('start-mission')">start mission</button>
            <button v-else @click="$emit('reset-vote')">pick new team</button>
          </template>
          <button v-else @click="$emit('next-mission')">next round</button>
        </template>
      </template>
    </div>
    <div v-else-if="player.leader" class="window intro">
      <h1>Vote Complete</h1>
      <button @click="calcVote">reveal</button>
    </div>
  </div>
</template>

<script>
import { db } from "@/store/firebase";

export default {
  name: "Reveal",
  props: ["game", "player"],
  data() {
    return {
      show: false
    };
  },
  methods: {
    calcVote() {
      const refURL = `/games/${this.game.id}`;
      const {
        team_votes,
        mission_votes,
        players,
        missions,
        score,
        round
      } = this.game;
      const mission = missions[round - 1];
      const votes = this.game[`${this.voteType}_votes`];
      const votePassed = this.voteType == 'mission' ?
        votes.fail < mission.votes_fail :
        votes.pass > votes.fail;
      const voteKey = `votes_${votePassed ? "passed" : "failed"}`;

      let updates = {
        [`${refURL}/${this.voteType}_votes/`]: {
          ...votes,
          [voteKey]: votes[voteKey] + 1
        },
        [`${refURL}/reveal_score/`]: true
      };

      if (this.voteType == "mission") {
        const winTeam = votePassed ? "resistance" : "spies";
        const newScore = score[winTeam] + 1;
        updates[`${refURL}/missions/${round - 1}/pass_fail`] = votePassed
        updates[`${refURL}/score/${winTeam}`] = newScore;
        if (newScore == 3) {
          updates[`${refURL}/game_over/`] = true;
        }
      } else if (!votePassed && team_votes.votes_failed == 4) {
        updates[`${refURL}/game_over/`] = true;
      }

      db.ref().update(updates, error => this.$emit('error', error));
    }
  },
  computed: {
    title() {
      const text = this.voteType == "mission" ? "Mission" : "Team Vote";
      return `${text} ${this.votePassed ? "Passed" : "Failed"}`;
    },
    votePassed() {
      const { team_votes, mission_votes, missions, round } = this.game;
      const teamPassed = team_votes.votes_passed > 0;
      const missionPassed = missions[round - 1].pass_fail;
      return (this.voteType == "mission" && missionPassed) ||
        (this.voteType == "team" && teamPassed);
    },
    voteType() {
      const {
        team_votes,
        mission_votes,
        missions,
        round,
        players
      } = this.game;
      const mission = missions[round - 1];
      const teamVoted = team_votes.pass + team_votes.fail == Object.keys(players).length;
      const missionVoted = mission_votes.pass + mission_votes.fail == mission.players;
      return missionVoted ? "mission" : "team";
    },
    playersPassed() {
      const { players } = this.game;
      return Object.keys(players).filter(name => players[name].last_vote);
    },
    playersFailed() {
      const { players } = this.game;
      return Object.keys(players).filter(name => !players[name].last_vote);
    },
    spiesWin() {
      return this.game.score.spies > this.game.score.resistance || this.game.team_votes.votes_failed == 5;
    }
  }
};
</script>

<style lang="stylus" scoped>
.reveal
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  display flex
  align-items flex-start
  justify-content center
  background-color rgba(0,0,0,0.75)

  @media (min-width: breaks.sm)
    padding-top 25%

.window
  width 100%
  height 100%
  text-align center
  padding 150px 24px 24px
  background-color: colors.dark - 30%
  z-index 1
  @media (min-width: breaks.sm)
    top 30%
    left 50%
    width 500px
    height auto
    padding 24px
    border-radius 8px

h1
  font-size 32px
h2
  font-size 24px

.team-results
  display flex
  justify-content space-between
  margin-bottom 24px

  .player-col
    width calc(50% - 12px)

    p
      width 100%

  .pass
    background-color: colors.success
  .fail
    background-color: colors.error

  span
    color: colors.light

.mission-results
  display flex
  justify-content space-between
  width 200px
  margin 0 auto
  font-size 20px
  font-weight bold
  letter-spacing 2px

  .total-pass
    color: colors.success
  
  .total-fail
    color: colors.error


.player
  width 100%
  font-size 20px
  font-weight bold
  letter-spacing 2px
  line-height 1
  text-align center
  text-transform uppercase
  border-radius 4px
  padding 6px 12px
  margin 0 0 12px
  &:last-child
    margin 0

.winner-icon
  font-size 60px
  margin-bottom 24px

  &.spy
    color: colors.spy
  &.resistance
    color: colors.resistance
</style>
